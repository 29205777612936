import Vue from 'vue'
import toast from './Toast'

const toastConstructor = Vue.extend(toast)

const Toast = function(options) {
  if (Vue.prototype.$isServer) return
  options = options || {}
  if (typeof options === 'string') {
    options = {
      message: options,
    }
  }

  const instance = new toastConstructor({ data: options })
  instance.vm = instance.$mount()
  document.body.appendChild(instance.vm.$el)
  instance.vm.show()
  return instance.vm
}

export default Toast
