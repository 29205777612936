export const TrendRouter = [
  {
    path: '/tendency',
    component: () => import(/* webpackChunkName: "TendencyLayout" */ '@/views/Tendency/Layout.vue'),
    children: [
      {
        path: '',
        name: 'Tendency',
        meta: {
          theme: '#3fdeae',
        },
        component: () => import(/* webpackChunkName: "Tendency" */ '@/views/Tendency/Index.vue'),
      },
      // 趋势详情页
      {
        path: 'detail',
        name: 'TendencyDetail',
        meta: {
          theme: '#3fdeae',
        },
        component: () =>
          import(/* webpackChunkName: "TendencyDetail" */ '@/views/Tendency/TendencyDetail.vue'),
      },
      // 类目详情页
      {
        path: 'category',
        name: 'CategoryDetail',
        meta: {
          theme: '#FFFFFF',
        },
        component: () =>
          import(/* webpackChunkName: "CategoryDetail" */ '@/views/Tendency/CategoryDetail.vue'),
      },
      // 二级类目详情页
      {
        path: 'category/second',
        name: 'CategorySecondList',
        meta: {
          theme: '#FFFFFF',
        },
        component: () =>
          import(/* webpackChunkName: "CategorySecondList" */ '@/views/Tendency/CategorySecondList.vue'),
      },
      // 最高消费详情页
      {
        path: 'highest',
        name: 'HighestRecordList',
        meta: {
          theme: '#FFFFFF',
        },
        component: () =>
          import(
            /* webpackChunkName: "HighestRecordList" */ '@/views/Tendency/HighestRecordList.vue'
          ),
      },
    ],
  },
]
