import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
dayjs.extend(duration)

// 全局过滤器
const filters = {
  /**
   * 日期格式化
   * @param {string} value 日期字符串
   * @param {string} format 格式
   * @return {string}
   */
  formatDate: function(value, format = 'YYYY-MM-DD HH:mm:ss') {
    if (!value) {
      return value
    }
    return dayjs(value).format(format)
  },
  /**
   * 日期格式化 月-日(星期) 如: 6-1(二)
   * @param value 日期字符串
   */
  formatWithWeek: function(value) {
    if (!value) {
      return value
    }
    const WEEKDAY_MAP = {
      Mon: '一',
      Tue: '二',
      Wed: '三',
      Thu: '四',
      Fri: '五',
      Sat: '六',
      Sun: '日',
    }
    value = value.toString()
    let weekDay = dayjs(value).format('ddd')
    return `${dayjs(value).format('MM-DD')} (${WEEKDAY_MAP[weekDay]})`
  },
  /**
   * 星期格式化
   * @param {string} value 英文简写的星期
   * @param {boolean} isShorthand  是否返回简写
   * @return {string}
   */
  formatWeekDay: function(value, isShorthand = true) {
    if (!value || value === '') {
      return '未知'
    }

    const WEEKDAY_MAP = {
      Mon: '星期一',
      Tue: '星期二',
      Wed: '星期三',
      Thu: '星期四',
      Fri: '星期五',
      Sat: '星期六',
      Sun: '星期日',
    }
    return isShorthand ? WEEKDAY_MAP[value].substring(2, 3) : WEEKDAY_MAP[value]
  },
  /**
   * 格式化数字, 自定义保留位数, 整数无小数
   * @param value
   * @param {number} keepDecimal 保留几位小数
   */
  formatReadableNumber: (value, keepDecimal = 2) => {
    // 判断是数字 or NaN
    let num = Number(value)
    if (Number.isNaN(num)) {
      return '未知'
    }

    // 整数
    if (Number.isInteger(num)) {
      return num
    }

    return Number(num.toFixed(keepDecimal))
  },
  /**
   * 将时长转换为可读字符串
   * 如: 1天3小时27分钟8秒
   * @param value 时长 单位: 秒
   */
  turnSecondsToDuration: function(value) {
    const MINUTE_MS = 60 * 1000
    const HOUR_MS = 60 * MINUTE_MS
    const DAY_MS = 24 * HOUR_MS

    let ms = value * 1000
    const day = dayjs.duration(ms).days()
    ms = ms - day * DAY_MS
    const hours = dayjs.duration(ms).hours()
    ms = ms - hours * HOUR_MS
    const minutes = dayjs.duration(ms).minutes()
    ms = ms - minutes * MINUTE_MS

    const dayStr = day > 0 ? `${day} 天 ` : ''
    const hourStr = hours > 0 ? `${hours} 小时 ` : ''
    const minuteStr = minutes > 0 ? `${minutes} 分钟 ` : ''
    const secondStr = ms > 0 ? `${ms / 1000} 秒 ` : ''

    return `${dayStr}${hourStr}${minuteStr}${secondStr}`
  },
  /**
   * 转换货币单位 分 -> 元
   */
  formatFenToYuan: function(value) {
    return value / 100
  },
}

export default filters
