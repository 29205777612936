<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import vConsole from 'vconsole'

export default {
  name: 'App',
  mounted() {
    if (process.env.NODE_ENV !== 'production') {
      new vConsole()
    }
  },
}
</script>

<style lang="scss">
@import '@/assets/styles/reset.scss';
</style>
