<template>
  <svg
    v-if="icon !== ''"
    class="iconfont"
    :style="iconStyle"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
  >
    <use :xlink:href="icon"></use>
  </svg>
</template>

<script>
import iconMapping from '@/assets/iconfont/mappingWithName.json'
import '@/assets/iconfont/iconfont'

// iconfont 上的字体图标
export default {
  name: 'RichIcon',
  props: {
    name: {
      type: String,
      required: true,
    },
    // 填设计稿上的大小, 单位 px
    size: {
      type: Number,
      default: 60,
    },
  },
  watch: {
    name: function() {
      this.getIcon()
    },
  },
  data() {
    return {
      icon: '',
    }
  },
  created() {
    this.getIcon()
  },
  computed: {
    iconStyle() {
      const designWidth = 750

      return {
        width: ((this.size / designWidth) * 100).toFixed(2) + 'vw',
        height: ((this.size / designWidth) * 100).toFixed(2) + 'vw',
      }
    },
  },
  methods: {
    getIcon() {
      if (this.name.includes('icon_')) {
        this.icon = `#${this.name}`
        return
      }
      if (this.name !== '' && iconMapping[this.name]) {
        const { font_class } = iconMapping[this.name]
        this.icon = `#${font_class}`
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.iconfont {
  flex: none;
}
</style>
