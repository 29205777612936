import Vue from 'vue'
import VueRouter from 'vue-router'
import { TrendRouter } from '@/router/trend'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: () => import(/* webpackChunkName: "HomePage" */ '@/views/HomePage.vue'),
  },
  // 会员页
  {
    path: '/purchase/vip',
    name: 'VipPurchase',
    component: () => import(/* webpackChunkName: "VipPurchase" */ '@/views/VipPurchase/Index.vue'),
  },
  {
    path: '/agreement/vip',
    name: 'VipPolicy',
    component: () => import(/* webpackChunkName: "VipPurchase" */ '@/views/VipPolicy.vue'),
  },
  // 用户协议
  {
    path: '/agreement/user',
    name: 'UserPolicy',
    component: () => import(/* webpackChunkName: "UserPolicy" */ '@/views/UserPolicy.vue'),
  },
  // 隐私协议
  {
    path: '/agreement/privacy',
    name: 'PrivacyPolicy',
    component: () => import(/* webpackChunkName: "PrivacyPolicy" */ '@/views/PrivacyPolicy.vue'),
  },
  // 自动续费协议（仅苹果iOS）
  {
    path: '/purchase/subscriptions',
    name: 'SubscriptionPolicy',
    component: () => import(/* webpackChunkName: "SubscriptionPolicy" */ '@/views/SubscriptionPolicy.vue'),
  },
  // 邀请码页面
  {
    path: '/invite',
    name: 'Invite',
    component: () => import(/* webpackChunkName: "Invite" */ '@/views/Invite.vue'),
  },
  // 帮助中心
  {
    path: '/help',
    name: 'HelpCenter',
    component: () => import(/* webpackChunkName: "HelpCenter" */ '@/views/HomePage.vue'),
  },
  {
    path: '/download',
    name: 'DownloadGuide',
    component: () => import(/* webpackChunkName: "DownloadGuide" */ '@/views/DownloadGuide.vue'),
  },
  ...TrendRouter,
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "NotFound" */ '@/views/404.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
