import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Toast from '@/components/Toast/index'
import RichIcon from '@/components/RichIcon'
import { Popup, Picker } from 'vant'
import filters from '@/utils/filters'

Vue.config.productionTip = false
Vue.prototype.$toast = Toast

Vue.component('rich-icon', RichIcon)

import { imageCrop, imageZoom } from './utils/imageSuffix'

Vue.prototype.$imageCrop = imageCrop
Vue.prototype.$imageZoom = imageZoom

Vue.use(Popup)
Vue.use(Picker)

// 注册全局过滤器
for (let key in filters) {
  Vue.filter(key, filters[key])
}

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
