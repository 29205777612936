// imageMogr2/auto-orient 根据exit信息自动旋正
// imageslim 缩小文件体积
// thumbnail 缩放
// crop 裁剪

/**
 * 图片裁剪 确定宽高
 * @param {string} url 图片url
 * @param {number} width
 * @param {number} height
 */
export function imageCrop(url, width, height) {
  if (!url && url === '') {
    return
  }
  if (!checkPhoto(url)) {
    return url
  }
  let defaultSuffix = '?imageslim&imageMogr2/auto-orient'
  let thumbSuffix = ''
  if (width && height) {
    thumbSuffix = `/gravity/Center/thumbnail/${width}x/crop/${width}x${height}`
  }
  return `${url}${defaultSuffix}${thumbSuffix}`
}

/**
 * 图片等比缩放，不裁剪。如果只指定 width 参数则表示限定宽（长自适应），只指定 height 参数则表示限定长（宽自适应）
 * @param {string} url 图片url
 * @param {number} width
 * @param {number} height
 */
export function imageZoom(url, width, height) {
  if (!url && url === '') {
    return
  }
  if (!checkPhoto(url)) {
    return url
  }
  let defaultSuffix = '?imageslim&imageMogr2/auto-orient'
  let thumbSuffix = '&imageView2/2/'

  if (width) {
    thumbSuffix = `${thumbSuffix}w/${width}/`
  }
  if (height) {
    thumbSuffix = `${thumbSuffix}h/${height}`
  }

  return `${url}${defaultSuffix}${thumbSuffix}`
}

const checkPhoto = url => {
  return /cdn\.picku\.com/.test(url)
}
