<template>
  <transition name="fade">
    <div v-if="visible" class="toast-wrap">
      {{ message }}
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Toast',
  data() {
    return {
      visible: false,
      message: '',
      duration: 1400,
    }
  },
  methods: {
    show() {
      this.visible = true
      setTimeout(() => {
        this.close()
      }, this.duration)
    },
    close() {
      this.visible = false
    },
  },
}
</script>

<style lang="scss" scoped>
.toast-wrap {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  color: $white;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
